import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
// import SEO from "../components/seo"
import "../assets/sass/footer.sass"

import phoneIcon from "../assets/icons/phone.svg"
import emailIcon from "../assets/icons/email.svg"

const Footer = () => (
    <footer>

        <div className="footer-options contact-footer">
            <h3>Contact Us:</h3>
            
            <a className="contact-link" href="tel:630-664-3463">
                <img className="contact-image-links" src={phoneIcon} height="15px" alt="Phone Icon"/>
                <p>630-664-3463</p>
            </a>
            <a className="contact-link" href="mailto:kevinslandscapingco@gmail.com">
                <img className="contact-image-links" src={emailIcon} height="15px" alt="Phone Icon"/>
                <p>kevinslandscapingco@gmail.com</p>
            </a>
            <p className="accept-cedit-statement">We accept credit cards</p>
        </div>
        <div className="footer-options">
            <p>© 2019 Kevin's Landscaping LLC</p>
            <p className="design-by">Website Maintained By:<a className="design-by" href="https://davidpina.dev">David Pina</a></p>
        </div>

    </footer>
  )

  export default Footer