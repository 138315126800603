// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mulch-js": () => import("./../../../src/pages/mulch.js" /* webpackChunkName: "component---src-pages-mulch-js" */),
  "component---src-pages-patios-js": () => import("./../../../src/pages/patios.js" /* webpackChunkName: "component---src-pages-patios-js" */),
  "component---src-pages-pavers-js": () => import("./../../../src/pages/pavers.js" /* webpackChunkName: "component---src-pages-pavers-js" */),
  "component---src-pages-planting-js": () => import("./../../../src/pages/planting.js" /* webpackChunkName: "component---src-pages-planting-js" */),
  "component---src-pages-service-area-js": () => import("./../../../src/pages/service-area.js" /* webpackChunkName: "component---src-pages-service-area-js" */),
  "component---src-pages-services-commercial-js": () => import("./../../../src/pages/services/commercial.js" /* webpackChunkName: "component---src-pages-services-commercial-js" */),
  "component---src-pages-services-hardscaping-js": () => import("./../../../src/pages/services/hardscaping.js" /* webpackChunkName: "component---src-pages-services-hardscaping-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-landscaping-js": () => import("./../../../src/pages/services/landscaping.js" /* webpackChunkName: "component---src-pages-services-landscaping-js" */),
  "component---src-pages-services-snowplowing-js": () => import("./../../../src/pages/services/snowplowing.js" /* webpackChunkName: "component---src-pages-services-snowplowing-js" */)
}

