import { Link } from "gatsby"
import PropTypes from "prop-types"
import React , {Component} from "react"
import Image from "./image"

import phoneIcon from "../assets/icons/phone.svg"
import emailIcon from "../assets/icons/email.svg"
import menuIcon from "../assets/icons/menuu.svg"

import "../assets/sass/header.sass"
import "../assets/layout.css"

class Header extends Component {
	
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    // function to handle click
    handleClick() {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    }
	
		
	
	render(){
		const menuActive = this.state.showModal ? 'phone-menu-toggle' : '';
		const dropdownOptions = this.state.showModal ? '-visible' : ''
		
		return(
            <div className="app-header-wrapper">
                <header>
                    <div className="logo top-header logo-container logo-image-container">
                        <Link to="/">
                            <Image filename="logo-kevinland.png"/>
                        </Link>
                    </div>
        
                    <div className="contact-info top-header">

                        <div>
                            <img className="header-contact-links" src={phoneIcon} height="35px" alt="Phone Icon"/> 
                            <a className="header-contact-links" href="tel:630-664-3463">630-664-3463</a>
                            <img className="header-contact-links" src={emailIcon} height="35px" alt="Email Icon"/> 
                            <a className="header-contact-links" href="mailto:kevinslandscapingco@gmail.com">Kevinslandscapingco@gmail.com</a> 
                        </div>

                        <ul className="website-options">
                            <Link to="/services">
                                <li className="nav-options">
                                    Our Services
                                </li>
                            </Link>
                            <Link to="/services/commercial">
                                <li className="nav-options">
                                    Commercial Work
                                </li>
                            </Link>
                            <Link to="/contact">
                                <li className="nav-options">
                                    Contact Us
                                </li>
                            </Link>
                            <Link to="/about">
                                <li className="nav-options">
                                    About Us
                                </li>
                            </Link>
                        </ul>

                    </div>

                    <div className="contact-mobile-info">
                        <div className="header-contact-links-mobile">
                            <Link href="tel:630-664-3463">
                                <img  src={phoneIcon} height="25px" alt="Phone Icon"/> 
                                <p>630-664-3463</p>
                            </Link>
                        </div>

                        <div className="header-contact-links-mobile">
                            <Link href="mailto:kevinslandscapingco@gmail.com">
                                <img  src={emailIcon} height="25px" alt="Phone Icon"/> 
                                <p>Kevinslandscapingco@gmail.com</p>
                            </Link>
                        </div>
                    </div>

                    <div onClick={this.handleClick} className={`pulse phone-menu-icon phone-menu-toggle-two ${menuActive}`}>
                        <img src={menuIcon} height="30px" alt="Menu"/>
                    </div>
                </header>
                <ul className={`netflix-nav netflix-nav-white mobile-menu-nav${dropdownOptions} netflix-nav${dropdownOptions} netflix-nav${dropdownOptions}`} onClick={this.handleClick} >
                    <div className="inner-mobile-side-bar">
                    <Link to="/services">
                        <li>
                            Our Services
                        </li>
                    </Link>
                    <Link to="/services/commercial">
                        <li>
                            Commercial Work
                        </li>
                    </Link>
                    <Link to="/contact">
                        <li>
                            Contact Us
                        </li>
                    </Link>
                    <Link to="/about">
                        <li>
                            About Us
                        </li>
                    </Link>
                    </div>
                </ul>
            </div>
	    )}
	}
	
	Header.propTypes = {
		siteTitle: PropTypes.string,
	}
	
	Header.defaultProps = {
		siteTitle: ``,
	}

export default Header
	